/*
* Fonts
*/
/*
@font-face {
  font-family: 'simhei';
  src: url("./fonts/simhei/simhei.ttf") format("ttf");
}
*/
/*
* Styling
*/
h1 {
  color: #000;
  font-size: 1.8em;
  font-family: 'Raleway', 'Helvetica', 'Arial', sans-serif;
}
h2 {
  color: #000;
  font-family: 'Roboto Slab', Verdana, Helvetica, Arial, sans-serif;
  font-size: 1.4em;
}

h3 {
  color: #000;
  font-size: 1.8em;
  font-family: 'Futura', 'Helvetica', 'Arial', sans-serif;
  font-weight: 700;
}

h4 {
  color: #000;
  font-family: 'Raleway', Verdana, Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  font-weight: 300;
  font-style: italic;
}

h6 {
  color: #000;
  display: inline-block;
  font-family: 'Raleway', Verdana, Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  font-weight: 300;
  font-style: italic;
}


.list h3 {
  font-size: 1.2em;
}

.list h4 {
  font-size: 1em;
}

h5 {
  color: #000;
  font-size: 1em;
  text-align: left;
}

p {
  color: #000;
}

.text-center {
  text-align: center;
}

.v-center {
  display: flex;
  align-items: center;
}

/*
* General
*/

body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

a {
  color: inherit;
}

.hover-scale > *  {
  transition: all 0.5s ease-in-out;
}

.hover-scale:hover > *,
.hover-scale:focus > *,
.hover-scale:active > *  {
  font-size: 1.8em;
}

.glyphicon-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.glyphicon-medium {
  font-size: 1.5em;
}

.btn-default.action-button {
  background: #424242;
  border-radius: 50%;
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.12), 0 6px 6px 0 rgba(0,0,0,0.24);
  color: #FFF;
}

.btn-default.action-button .glyphicon {
  min-width: 1.5em;
  line-height: 2em;
}

.btn-default.active {
  background-color: #e0e0e0 !important;
}
/*
* MapLayer
*/

.leaflet-container {
  height: calc(100% - 50px);
  position: fixed;
}
/*
* ControlLayer
*/
.bottom-controls {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.bottom-controls > * {
  margin-right: 20px;
}

.bottom-controls form {
  display: inline-block;
  font-family: 'agenda-light';
  font-size: 24px;
  color: #616161;
}

.view-filter-toolbar {
  transition: all 3s ease-in-out;
}

.view-filter-toolbar.hide {
  transform: translateY(-100%);
}

.view-filter-toolbar .btn {
  padding: 25px;
}

.view-filter-toolbar .btn-group {
  float: none;
}

.view-filter-toolbar .btn-group>.btn:first-child {
  border-top-left-radius: 0px;
}
.view-filter-toolbar .btn-group>.btn:last-child {
  border-top-right-radius: 0px;
}

.btn-default {
  font-family: 'Raleway', Verdana, Helvetica, Arial, sans-serif;
  font-size: 1.2em;
  text-shadow: none;
  background-color: #FFF;
  background-image: none;
  border: none;
}

.control-layer {
  z-index: 800;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  pointer-events: visiblePainted; /* IE 9-10 doesn't have auto */
	pointer-events: none;
}

.control-layer > * {
  pointer-events: auto;
}

.leaflet-control-attribution {
  display: none;
}

/*
* DetailLayer
*/

.detail-card {
  background: #FFF;
  color: #616161;
}

.detail-card > .row {
  margin-left: 0;
  margin-right: 0;
}

.detail-header {
  margin: 0 0px;
  text-align: left;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  padding-bottom: 30px;
  text-align: left;
}

.detail-header .header {
  flex-grow: 2; /* default 0 */
  border-bottom: 1px solid #D3D3D3;
  margin-bottom: 25px;
}

/*
.Aligner {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Aligner-item {
  max-width: 50%;
}

.Aligner-item--top {
  align-self: flex-start;
}

.Aligner-item--bottom {
  align-self: flex-end;
}*/
.detail-filters .btn-group {
  display: flex;
  width: 100%;
}

.detail-filters .btn-group .btn {
  flex: 1 0 0;
  border-radius: none;
}

.detail-layer {
  background: rgba(255,255,255,0.94);
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  border-radius: 2px;
  color: #2c3e50;
  min-height: 100vh;
  height: auto;
  margin: 0 auto;
  margin-right: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: transform 2s 2s ease-out;
  width: 40%;
  z-index: 900;
}

.detail-layer.list {
  width: 30%;
  min-width: 300px;
}

.detail-layer.container {
  padding-left: 0px;
  padding-right: 0px;
}

.detail-layer .body {
  padding: 0 25px;
}

.detail-layer.show {
  transform : translateX(0);
}

.detail-layer.hide {
  transform : translateX(-100%);
}

.detail-layer h5 {
}

.detail-marker {
  width: 100px;
  height: 100px;
}

.detail-marker .border {
  fill: transparent;
  stroke: #FFF;
  stroke-width: 1;
}

.detail-marker .center {
  fill: #FFF;
}

.detail-marker text {
  font-family: 'agenda-bold';
  font-size: 100px;
  fill: #FFF;
  fill-opacity: 0.2;
  transform: translate(-18px, 32px);
}

.detail-period {
  display: flex;
  justify-content: center;
}

.detail-period .time-button {
  color: #278DAB;
}

.detail-period .time-button.disabled {
  opacity: 0.2;
  pointer-events: none;
  cursor: default;
}

a:hover h3,
a:hover h4 {
  text-decoration: none;
}

.detail-back {
  cursor: pointer;
  padding: 10px 0px;
  border-top: 1px solid #eee;
}

/* List */
.list-group-item {
  background: none;
  text-align: left;
  padding: 10px 25px;
}

.list-group-item h3,
.list-group-item h4 {
  cursor: pointer;
}

.close-button {
    cursor: pointer;
}

.icon-small {
  margin-right: 20px
}

.icon-big {
  padding: 0px 20px;
  text-align: center;
  font-size: 1.8em;
}

a:hover .iconm-big{
  box-shadow: 0 0 6px 0 rgba(0,0,0,0.12), 0 6px 6px 0 rgba(0,0,0,0.24);
}
.navbar {
  z-index: 901;
  margin-bottom: 0px;
  background-image: linear-gradient(to bottom,#fff 0,#fff 100%);
}

.navbar-brand {
    padding: 10px 15px;
    margin-right: 25px;
}

.content {
  position: relative;
  margin-top: 50px;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.padding-top-40 {
  padding-top: 40px;
}

.padding-bottom-100 {
  padding-bottom: 100px;
}

.width-70 {
  width: 70%;
}

.detail-img {
  width: 90%;
  padding: 20px;
}

.detail-video {
  width: 80%;
  height: auto;
  padding: 50px 20px;
  margin: auto;
}
/*AUDIO*/

.audio-container {
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  position: fixed;
  z-index: 901;
  background: #FFF;
  bottom: 0;
  left: 18%;
  display: flex;
  align-items: center;
}

@media only screen
and (min-device-width : 768px)
and (max-device-width : 1024px)
and (orientation : portrait) {
  /* STYLES GO HERE */
  .audio-container {
    left: 6%;
  }
}

@media only screen
and (min-device-width : 375px)
and (max-device-width : 667px)
and (orientation : portrait) {

  .audio-container {
    left: 6%;
  }/* STYLES GO HERE */ }
.audio-close {
  background: #fff;
  cursor: pointer;
}

.player {
  transition: color 125ms ease-in-out;
  background: white;
  position: relative;
  padding: 1rem;
  z-index: 5;
  font-size: 1.4rem;
  display: flex;
  align-items: center;
}
.player__control {
  margin-right: 1.2rem;
  min-width: 10rem;
  transition: fill 125ms ease-in-out;
}
.player__control__icon {
  cursor: pointer;
  display: inline-block;
  margin-right: 0.4rem;
}
.player__control__icon--play {
}
.player__control__icon--pause {
  fill: #f50;
}
.player__track--active {
  border-color: #a2a2a2;
  background: white;
  z-index: 10;
}
.player__display {
  flex: 1 1 100%;
  margin-bottom: 0;
}
.player__progress {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: center;
  margin: 0;
}
.player__progress__position {
  text-align: right;
  margin-right: 1rem;
  margin-left: 0;
  font-feature-settings: tnum;
}
.player__progress__time {
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
  line-height: 1rem;
  color: #444;
  flex: 0 1 auto;
  vertical-align: middle;
}
.player__progress__bar {
  flex: 1 1 auto;
  width: 100%;
  display: block;
  padding: 0.5rem 0;
  margin: 0 0.5rem;
}
.player__progress__bar--container {
  cursor: pointer;
  display: block;
  position: relative;
  width: 100%;
  height: .4rem;
  background: #eee;
  border-radius: 2px;
  overflow: hidden;
  transform: translateZ(0);
}
.player__progress__bar--percent {
  transition: transform .2s;
  position: absolute;
  width: 100%;
  height: 4px;
  background: #f50;
  display: block;
}
.player__progress__bar--progress {
  background: pink;
}

.mobile-control {
  z-index: 898;
  position: fixed;
  top: 60px;
  right: 18px;
  font-family: 'Raleway', Verdana, Helvetica, Arial, sans-serif;
  font-size: 14px;
}

.btn-group, .btn-list {
  margin-right: 10px;
  border: 2px solid rgba(0,0,0,0.2);
  box-shadow: 0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22);
  cursor: pointer;
  font-family: 'Raleway', Verdana, Helvetica, Arial, sans-serif !important;
  font-size: 14px;
}

.btn-default:hover, .btn-default:focus, .btn-default.focus, .btn-default:active, .btn-default.active, .open>.dropdown-toggle.btn-default {
    color: #333;
    background-color: #fff;
    border-color: #adadad;
}

.detail-layer.list.hide {
  visibility: collapse;
}

.remove-list {
  cursor: pointer;
  float: right;
  padding: 5px 10px;
  margin-right: 10px;
}

.row.list-button {
  margin-right: 0px;
  margin-left: 0px;
}


._37_FJ {
        margin: auto;
        height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
}

._37_FJ > div {
    padding: 6px;
}
/* Mobileeees
*/
@media only screen
and (max-device-width : 667px)
and (orientation : portrait) {
  h5 {
    font-size: 1.2em;
  }

  .icon-big {
    font-size: 2.8em;
  }

 /* STYLES GO HERE */
 .leaflet-container {
   position: relative;
   height: calc(100vh - 50px);
 }

 .leaflet-top, .leaflet-bottom {
   z-index:899;
 }

 .detail-layer,
 .detail-layer.list {
   width: 100%;
   position: absolute;
   min-height: 100vh;
   padding-bottom: 60px;
 }

 .audio-container{
   left:0;
 }
}

